import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  page {
    overflow: visible;
  }

  h3 {
    margin-bottom: 8px;
    padding: 0 24px;

    @media (max-width: 768px) {
      padding: 0;
      max-width: calc(100vw - 32px);
    }
  }

  .map-container {
    width: 100%;
    height: calc(100vh - 350px);
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    margin-top: 16px;

    @media (max-width: 768px) {
      width: 100vw;
      border-radius: 0;
    }
  }

  .center-marker-wrapper {
    padding-right: 8px;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    .center-marker {
      height: 50px;
      width: 50px;
      background: ${props => props.theme.activeBackground};
      border-radius: 24px;
      border-bottom-left-radius: 0;
      box-shadow: 4px 4px 12px ${props => props.theme.shadow};
      overflow: hidden;
      display: flex;
      align-items: center;
      border-bottom: 5px solid ${props => props.theme.blue};
      position: absolute;
      bottom: 0;
      left: 0;

      .client-logo {
        height: 110%;
        width: 110%;
        object-fit: cover;
      }
    }
  }

  button {
    margin: 0 16px;
    margin-bottom: 24px;
    padding: 0 16px;
    width: 160px;

    &[data-variant='as-text'] {
      width: auto;
    }
  }

  button.continue-button {
    @media (max-height: 1100px) {
      position: fixed;
      bottom: 16px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 250;
      width: 150px;
      justify-content: center;
      box-shadow: 0 0px 4px #454f5b44;
    }
  }

  .explainer {
    max-width: 500px;
    text-align: center;
  }

  .fa-info-circle {
    color: ${props => props.theme.accent};
    margin-right: 4px;
  }
`
