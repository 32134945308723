import React, { useEffect, useState } from 'react'
import * as successAnimation from 'src/assets/animations/success.json'
import { Wrapper } from './styles'
import { Panel } from '../../HomeScreen/styles'
import { HolderCode, BusinessOrder, BusinessOrderStatus, BusinessOrderPaymentType, ProductType } from '@meniudigital/shared'
import Lottie from 'lottie-react'
import Button from 'src/global/Button'
import { businessOrderEndpoints } from 'src/api'
import { faHomeLg } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from 'react-router-dom'
import { Screen } from 'src/screens'
import OrderSummary from '../ChooseHolders/BusinessOrderSummary'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { clearOrderIntent } from 'src/state/business-orders'
import LoadingProgressBar from 'src/global/LoadingProgressBar'
import { getCurrentUser } from 'src/state/users'

export default function CompletedOrder() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state.users)
  const [order, setOrder] = useState<BusinessOrder | null>(null)
  const { id: orderId } = useParams()

  useEffect(() => {
    dispatch(getCurrentUser())
    getOrder()
  }, [])

  const getOrder = async () => {
    const fetchedOrder = await businessOrderEndpoints.getById(orderId!)

    if (fetchedOrder.status === BusinessOrderStatus.Failed || fetchedOrder.status === BusinessOrderStatus.Initiated) {
      navigate(`/failed-order/${orderId}`)
    } else {
      dispatch(clearOrderIntent())
      setOrder(fetchedOrder)
    }
  }

  if (!order || !user) {
    return <LoadingProgressBar />
  }

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const pageTitle =
    order.paymentType === BusinessOrderPaymentType.OnlineCard ? 'Plată efectuată cu succes!' : 'Comandă înregistrată'

  const hasOrderedSubscription = order.products.some(x => x.type === ProductType.Subscription)
  const hasOrderedHolders = order.products.some(x => x.type === ProductType.Holder && x.quantity > 0)

  return (
    <Wrapper>
      <h2 className="title">{pageTitle}</h2>

      <Panel className="centered">
        {hasOrderedSubscription && (
          <div className="top-left-business">
            <img src="/logo-white.svg" />
            <div className="brand-texts">
              <div className="brand-name">MeniuDigital</div>
              Business
            </div>
          </div>
        )}
        <Lottie {...lottieOptions} style={{ width: 200 }} />
        {order.paymentType === BusinessOrderPaymentType.OnlineCard ? (
          <p>
            Am înregistrat plata pentru comanda ta cu numărul <b>{order.orderNumber}</b>.{' '}
            {hasOrderedSubscription && <p>Abonamentul tău a fost achiziționat și activat cu succes!</p>}
            {hasOrderedHolders && (
              <p>
                Începem crearea și personalizarea produselor comandate și te anunțăm de îndată ce acestea au plecat înspre tine!
              </p>
            )}
            {order.status !== BusinessOrderStatus.PendingActivation && order.status !== BusinessOrderStatus.Activated && (
              <p className="error">
                Comanda a fost finalizată cu succes însă a avut loc o eroare la generarea sau trimiterea facturii electronice. Vom
                remedia problema în cel mai scurt timp.{' '}
              </p>
            )}
          </p>
        ) : (
          <p>
            Am înregistrat comanda ta cu numărul <b>{order.orderNumber}</b> și ți-am trimis factura proformă la{' '}
            <b>{user?.email}</b>. <br />
            <br />
            Urmează instrucțiunile primite pe e-mail pentru a efectua plata.
          </p>
        )}
        <OrderSummary
          order={{
            laminatedHolderCount: order.products.find(x => x.code === HolderCode.Laminated)?.quantity || 0,
            plexiglassHolderCount: order.products.find(x => x.code === HolderCode.Plexiglass)?.quantity || 0,
            stickerHolderCount: order.products.find(x => x.code === HolderCode.Sticker)?.quantity || 0,
          }}
        />
        <Button text="Mergi la ecranul principal" icon={faHomeLg} onClick={() => navigate(Screen.Home)} />
      </Panel>
    </Wrapper>
  )
}
