import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  overflow: hidden;

  .page {
    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
  }

  .panel {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;

    .title {
      font-weight: 700;
      margin-top: 24px;
    }

    .body {
      padding-top: 8px;
      padding-bottom: 24px;
    }

    &.any-selected {
      .payment-type:not(.selected) {
        transform: scale(0.97);
        color: ${props => props.theme.mutedText};

        .icon-wrapper {
          background: ${props => props.theme.mutedText}11;
          color: ${props => props.theme.mutedText};
        }
      }
    }

    .edit-button {
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 6px;
      padding-left: 10px;
      padding-top: 10px;
      border-top-left-radius: 32px;
      cursor: pointer;
      box-shadow: 0 0 14px ${props => props.theme.middleShadow};
      font-size: 14px;

      &:hover {
        background: ${props => props.theme.accentForHover};
        box-shadow: 0 0 14px ${props => props.theme.darkerShadow};

        svg {
          color: ${props => props.theme.accent};
        }
      }
    }
  }

  .payment-type {
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding-right: 16px;
    overflow: hidden;
    width: 450px;
    max-width: calc(100vw - 32px);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    font-size: 14px;
    box-shadow: 0 4px 12px ${props => props.theme.shadow};
    transition: all 0.15s ease-in-out;
    width: 100%;
    margin: 16px 0;

    :hover {
      color: ${props => props.theme.accent};
      background: ${props => props.theme.accentForHover};
    }

    .selected-indicator {
      position: absolute;
      color: ${props => props.theme.accent};
      opacity: 0;
      transition: all 0.3s ease-in-out;
      font-size: 22px;
      top: 10px;
      right: 14px;
      font-size: 16px;
    }

    .icon-wrapper {
      background: ${props => props.theme.accentForHover};
      color: ${props => props.theme.accent};
      border-radius: 100%;
      height: 100px;
      width: 100px;
      margin-left: -40px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: flex-end;
      padding-right: 22px;
      text-align: center;
      flex-direction: column;
      font-size: 20px;
      flex-shrink: 0;
      transition: all 0.2s ease-in-out;

      .icon {
        color: #fff;
      }
    }

    .name {
      font-size: 18px;
      font-weight: 600;
    }

    .description {
      font-size: 14px;
      color: ${props => props.theme.mutedText};
      margin-top: 4px;
      font-weight: 500;
      padding-right: 16px;
      word-break: break-word;

      @media (max-width: 768px) {
        padding-right: 0;
      }
    }

    :hover {
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
      transform: scale(1.015);

      .icon-wrapper {
        background: #11b7f322;
      }
    }

    &.selected {
      border-color: ${props => props.theme.accent};
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);

      transform: scale(1.01);

      .icon-wrapper {
        background: #11b7f3;
        color: ${props => props.theme.white};
      }

      .selected-indicator {
        opacity: 1;
      }
    }
  }

  .row-wrapper {
    padding: 0 8px;
  }

  p.info {
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 12px;

    .icon {
      margin-right: 5px;
      color: ${props => props.theme.accent};
    }
  }

  label.checkbox {
    cursor: pointer;
    display: block;
    font-size: 16px;
    text-transform: none;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;

    input[type='checkbox'] {
      display: none;

      + .label-text:hover:before {
        color: ${props => props.theme.accent};
      }

      + .label-text:before {
        content: '\f096';
        font-family: 'fontAwesome';
        line-height: 1;
        display: inline-block;
        font-size: 26px;
        margin-right: 5px;
        margin-left: 5px;
        position: relative;
        top: 4px;
        height: 40px;
      }

      :checked + .label-text:before {
        content: '\f14a';
        color: ${props => props.theme.accent};
        animation: tick 150ms ease-in;
      }

      :disabled + .label-text:before {
        content: '\f0c8';
        color: #dddfe6;
      }
    }
  }

  .continue-button {
    width: 234px;

    @media (min-height: 768px) {
      margin-bottom: 60px;
    }
  }

  textarea {
    border-radius: 8px;
    margin-bottom: 16px;
  }

  a {
    color: ${props => props.theme.mutedText};
  }
`
