import styled from 'styled-components/macro'

export const Modal = styled.div`
  position: fixed;
  z-index: 100;
  top: 12vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 600px;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};

  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  .product-image-wrapper {
    height: 55px;
  }

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
    padding-top: 0;

    .product-image-wrapper {
      height: 35px;
    }
  }

  .url {
    border-radius: 16px;
    padding: 4px 16px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 2px;

    .slug {
      font-weight: 700;
    }
  }

  .url svg {
    font-size: 14px;
    padding: 2px;
    margin-right: 4px;
    margin-bottom: -3px;
    color: ${props => props.theme.accent};
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      flex-grow: 0;
    }
  }

  .table-number {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 500;
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding: 10px 12px;
    margin-bottom: 16px;
    margin-top: 16px;
    flex-grow: 0;
    box-shadow: 0 0px 12px ${props => props.theme.middleShadow};

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .react-toggle-wrapper {
      margin-top: 0;
    }

    .icon {
      font-size: 16px;
      color: ${props => props.theme.accent};
    }

    span {
      margin-left: 16px;
      margin-right: 8px;
    }

    .input-wrapper {
      transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
      overflow: hidden;

      input {
        width: 155px;
        padding-right: 16px;
      }
    }
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;

  width: 100%;
  background: ${props => props.theme.pageBackground};
`

export const ModalContent = styled.div`
  padding: 16px;
  max-height: calc(100vh - 325px);
  overflow: auto;

  .centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .paragraph {
    text-align: center;
    width: min(calc(100% - 16px), 420px);
    margin-top: 16px;
  }

  .qr-code-wrapper {
    position: relative;
    margin-top: 16px;
    padding: 8px;
    border: 3px solid ${props => props.theme.accent};
    background: #fff;
    border-radius: 16px;

    .qr-code {
      width: 200px;
      display: block;
      border-radius: 6px;
      overflow: hidden;

      @media (max-width: 768px) {
        width: 120px;
      }
    }

    .center-icon {
      width: 26px;
      height: 26px;
      border-radius: 4px;
      padding: 4px;
      position: absolute;
      background: #fff;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      box-shadow: 0 0px 12px rgb(0 0 0 / 35%);
      -webkit-print-color-adjust: exact;
      -webkit-filter: opacity(1);
    }
  }

  .app {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: ${props => props.theme.darkBlue};
    vertical-align: middle;
  }

  .app-icon {
    width: 20px;
    height: 20px;
    padding: 3px;
    border-radius: 4px;
    box-shadow: 0 0px 4px #0003;
    margin-left: 2px;
    margin-right: 3px;
    margin-top: -2px;
  }
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  padding: 12px;
  background: ${props => props.theme.pageBackground};
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`
