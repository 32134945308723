import React, { useEffect, useState } from 'react'
import { statEndpoints } from 'src/api'
import { Wrapper } from './styles'
import { StatBusinessAggregates } from 'src/state/users'

const AggregatedBusinessStats = () => {
  const [stats, setStats] = useState<StatBusinessAggregates>()

  useEffect(() => {
    statEndpoints.getBusinessAggregates().then(x => setStats(x))
  }, [])

  if (!stats) {
    return <p>Loading...</p>
  }

  return (
    <Wrapper>
      <div className="stat">
        <span style={{ whiteSpace: 'nowrap' }}>
          <i className="fa fa-check" /> Active Subscriptions
        </span>
        <div className="stat-value">{stats.totalActiveSubscriptions}</div>
      </div>
      <div className="stat">
        <span>Expired</span>
        <div className="stat-value">{stats.totalExpiredSubscriptions}</div>
      </div>
      <div className="stat">
        <span>Total</span>
        <div className="stat-value">{stats.totalActiveSubscriptions + stats.totalExpiredSubscriptions}</div>
      </div>
    </Wrapper>
  )
}

export default AggregatedBusinessStats
