import React, { CSSProperties, useState } from 'react'
import CloseButton from '../CloseButton'
import { Wrapper } from './styles'

type Props = {
  text?: string
  textComponent?: React.ReactElement
  storageId?: string
  style?: CSSProperties
}

export default function InfoPanel({ text, textComponent, storageId, style }: Props) {
  const [isDismissed, setIsDismissed] = useState(false)

  if (isDismissed || (storageId && localStorage['hasDismissed:' + storageId])) {
    return null
  }

  return (
    <Wrapper className="panel" style={style}>
      <p>
        <i className="fa fa-info-circle"></i>
        {textComponent || text}
      </p>
      {storageId === 'personalize-info' && <div className="floating-emoji">👉</div>}
      <CloseButton
        title="Închide"
        onClick={() => {
          setIsDismissed(true)
          if (storageId) {
            localStorage['hasDismissed:' + storageId] = true
          }
        }}
      />
    </Wrapper>
  )
}
