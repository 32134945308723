import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent, ModalFooter } from './styles'
import CloseButton from 'src/global/CloseButton'
import { Backdrop } from '../ProductModal/styles'
import Button from 'src/global/Button'
import { faArrowRight, faArrowsAltH, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'
import { isBusiness } from '@meniudigital/shared'
import { usersSelector } from 'src/state/selectors'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { userEndpoints } from 'src/api'
import SubscriptionInfo from './SubscriptionDuration'
import { IS_DESKTOP_OR_TABLET } from '..'

export const RestrictionDescriptions = {
  ensureNoExpiry: 'Asigură-te că nu pierzi beneficiile MeniuDigital Business™',
  noAds: 'Scapă de orice fel de reclame de pe meniu sau interfața de administrare',
  phoneNumber: 'Obține rapid ajutorul de care ai nevoie prin suport telefonic',
  products: 'Adaugă un număr nelimitat de produse și categorii în meniul tău',
  stats: 'Monitorizează accesările meniului tău prin statistici în timp real',
  priorityTraffic: 'Asigură performanța maximă a meniului tău chiar și la orele de vârf',
  ordering: 'Oferă clienților experiența completă prin modulul de comandă la masă',
  languages: 'Adaugă meniul tău în până la 5 limbi străine, cu traducere automată',
  extraMenus: 'Adaugă meniuri extra pentru vinuri, deserturi sau bucătării speciale.',
  autoNutritionalValues: 'Obține un număr nelimitat de generări nutriționale!',
  customMenuMessage: 'Adaugă un mesaj personalizat pe meniul tău!',
  schedule: 'Configurează și afișează programul tău de lucru pe meniu',
  reservations: 'Permite clienților să rezerve mese direct din meniul tău digital',
}

export default function BusinessModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [descriptionType, setDescriptionType] = useState<keyof typeof RestrictionDescriptions>()
  const [showsDescriptions, setShowsDescriptions] = useState(false)
  const { user } = useSelector(usersSelector)
  const navigate = useNavigate()

  useEffect(() => {
    window.openBusinessModal = open
  }, [])

  const open = (type?: keyof typeof RestrictionDescriptions) => {
    setIsOpen(true)
    setDescriptionType(type)
  }

  const close = () => {
    setIsOpen(false)
  }

  const goToChooseSubscription = () => {
    window.hideSettingsModal?.()
    close()
    setTimeout(() => {
      setTimeout(() => {
        userEndpoints.registerUserAction({ actionType: 'HasClickedOnBuyBusiness', data: {} })
      }, 0)
      navigate(Screen.ChooseSubscription)
    }, 0)
  }

  const highlightIf = (type: keyof typeof RestrictionDescriptions) => {
    if (descriptionType === type) {
      return { style: { fontWeight: 700 } }
    }
  }

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} style={{ zIndex: 101 }} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          <img src="/logo-white.svg" style={{ width: 45, marginTop: '-2px', marginRight: '12px' }} />
          <span className="business-text">
            <div style={{ fontSize: 14, marginBottom: -3 }}>MeniuDigital</div>
            Business
          </span>
          <CloseButton color="#fff" onClick={close} title="Închide" style={{ marginTop: '-12px', marginRight: '-4px' }} />
        </Header>
        {descriptionType && <div className="perk-description">{RestrictionDescriptions[descriptionType]}</div>}
        <ModalContent>
          <ul className={showsDescriptions ? 'with-descriptions' : ''}>
            <li>
              <div className="title" {...highlightIf('reservations')}>
                Rezervări online{IS_DESKTOP_OR_TABLET && ' prin meniul digital'} <span className="new-tag">Nou</span>
              </div>
              <div className="description">
                Dupa vizualizarea meniului, clienții vor putea să rezerve o masă direct din browser.
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('customMenuMessage')}>
                Mesaj personalizat{IS_DESKTOP_OR_TABLET && ' pe meniu'} <span className="new-tag">Nou</span>
              </div>
              <div className="description">
                Întâmpină-ți clienții cu un mesaj personalizat adăugat de tine, vizibil în momentul deschiderii meniului digital.
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('schedule')}>
                Afișare program de lucru{IS_DESKTOP_OR_TABLET && ' pe meniu'}
              </div>
              <div className="description">
                Configurează și afișează programul de lucru al restaurantului tău pe meniul digital
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('noAds')}>
                Fără reclame
              </div>
              <div className="description">Interfața MeniuDigital rămâne curată, elegantă și fără distrageri</div>
            </li>
            <li>
              <div className="title" {...highlightIf('priorityTraffic')}>
                Trafic prioritar
              </div>
              <div className="description">Accesarea meniului tău va fi instantă, chiar și la orele de vârf</div>
            </li>
            <li>
              <div className="title">Imagini la calitate maximă</div>
              <div className="description">
                Imaginile produselor tale vor fi salvate xși afișate la rezoluție maximă, fără comprimare sau pierdere a
                detaliilor
              </div>
              <div className={`image-comparison ${showsDescriptions ? 'shown' : ''}`}>
                <img className="high-res" src="/big-cut.png" alt="Comparison uncompressed" />
                <img className="low-res" src="/small-cut.png" alt="Comparison compressed" />
                <div className="indicator">
                  <FontAwesomeIcon icon={faArrowsAltH} />
                </div>
              </div>
            </li>

            <li>
              <div className="title" {...highlightIf('autoNutritionalValues')}>
                Generări valori nutriționale nelimitate (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.meniudigital.ro/blog/calcul-nutritional-inteligenta-artificiala"
                >
                  vezi demo
                </a>
                )
              </div>
              <div className="description">
                Calculează automat valorile nutriționale ale produselor tale, folosind inteligența artificială.
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('ordering')}>
                Meniu pe tabletă (
                <a target="_blank" rel="noreferrer" href="https://www.meniudigital.ro/blog/meniu-digital-pe-tableta">
                  vezi instrucțiuni
                </a>
                )
              </div>
              <div className="description">
                Oferă meniul tău clienților pe tipul de tabletă potrivit pentru restaurantul tău, prin{' '}
                <strong>
                  <a href={`${process.env.REACT_APP_MENU_BASE_URL}/get-app`}>aplicația MeniuDigital.</a>
                </strong>
              </div>
            </li>
            <li>
              <div className="title">Backup zilnic</div>
              <div className="description">
                O copie a meniului tău este salvată la fiecare 12 ore, pentru a asigura disponibilitatea permanentă a acestuia
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('stats')}>
                Statistici detaliate în timp real
              </div>
              <div className="description">
                Ai la dispoziție grafice care ilustrează numărul de clienți care au accesat meniul în diferite intervale de timp.
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('languages')}>
                Până la 5 limbi diferite {showsDescriptions && '🇷🇴 🇬🇧 🇭🇺 🇩🇪 🇪🇸 🇫🇷 🇮🇹 🇳🇱 🇭🇺 🇮🇹 🇺🇦 🇷🇺'}
              </div>
              <div className="description">Oferă posibilitatea tuturor clienților tăi să vadă meniul în limba aleasă de ei.</div>
            </li>

            <li>
              <div className="title" {...highlightIf('extraMenus')}>
                Meniuri multiple
              </div>
              <div className="description">
                Structurează-ți produsele exact așa cum îți dorești, adăugând până la 4 secțiuni diferite de meniu.
              </div>
            </li>
            <li>
              <div className="title" {...highlightIf('phoneNumber')}>
                Suport telefonic
              </div>
              <div className="description">Îți stăm la dispoziție prin chat, email sau telefon oricând ai nevoie.</div>
            </li>

            <li>
              <div className="title" {...highlightIf('products')}>
                Produse și categorii nelimitate
              </div>
              <div className="description">
                Introdu întreg meniul tău, indiferent câte categorii, produse, sau variante de produs dorești să oferi.
              </div>
            </li>
          </ul>
          <Button
            onClick={() => setShowsDescriptions(!showsDescriptions)}
            variant="as-text"
            text={showsDescriptions ? 'Acunde detalii...' : 'Detalii...'}
            style={{ marginBottom: 8 }}
          />
        </ModalContent>
        <ModalFooter>
          <div className={`price ${isBusiness(user) ? 'active' : ''}`}>
            {isBusiness(user) ? (
              <span className="active-indicator">
                <FontAwesomeIcon icon={faCircleCheck} />
                ACTIV
              </span>
            ) : (
              <div>
                <div>100 RON/lună</div>
                <div className="invoicing-info-text">plata la 6 sau 12 luni</div>
              </div>
            )}
          </div>
          {isBusiness(user) ? (
            <SubscriptionInfo onRenewClick={() => close()} />
          ) : (
            <Button
              onClick={goToChooseSubscription}
              variant="as-text"
              text="Achiziționează"
              style={{ marginBottom: 8 }}
              icon={faArrowRight}
            />
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}
