import { ConsumerReservation, ConsumerReservationPatchType, ReservationResponseType } from '@meniudigital/shared'
import moment from 'moment'
import React, { useRef } from 'react'

import { Wrapper } from '../Reservation/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import Button from 'src/global/Button'
import { useDispatch } from 'react-redux'
import { getReservations, patchReservation } from 'src/state/consumer-reservations'
import ReasonBox from './ReasonBox'

type Props = {
  reservation: ConsumerReservation
}

export const START_OF_TODAY = +moment().startOf('day')

export default function Reservation({ reservation }: Props) {
  const dispatch = useDispatch()
  const isPastReservationTime = moment(reservation.requestedDateTime).isBefore(moment())

  const isRepliedTo = Boolean(reservation.waiterResponse)

  const isExpired = !isRepliedTo && isPastReservationTime
  const hasBody = isRepliedTo || isExpired

  const rejectionReason = useRef('')
  const acceptedReason = useRef('')

  const displayDate = moment(reservation.requestedDateTime).calendar(null, {
    sameDay: '[azi], D MMMM [la] HH:mm',
    nextDay: '[mâine], D MMMM [la] HH:mm',
    nextWeek: 'dddd, D MMMM [la] HH:mm',
    lastDay: '[ieri], D MMMM [la] HH:mm',
    sameElse: 'D MMMM [la] HH:mm',
  })

  const acceptTextFirstPart = `Ești sigur că dorești să accepți cererea de rezervare pentru ${
    reservation.numberOfPeople
  } persoane pentru data de ${moment(reservation.requestedDateTime).format('D MMMM')} la ora ${moment(
    reservation.requestedDateTime
  ).format('HH:mm')}?`
  const acceptTextSecondPart = `${reservation.consumer.fullName} va fi notificat prin e-mail la adresa ${reservation.consumer.emailAddress} și prin SMS. `

  const confirmAcceptReservation = async () => {
    await window.showModal({
      title: `Confirmă Rezervarea`,
      textComponent: (
        <>
          {acceptTextFirstPart}
          <br />
          <br />
          {acceptTextSecondPart}
          <ReasonBox type="accepted" onUpdate={reasonText => (acceptedReason.current = reasonText)} />
        </>
      ),
      confirmButtonText: 'Da, confirmă',
      cancelButtonText: 'Nu',
    })
    await dispatch(
      patchReservation({
        type: ConsumerReservationPatchType.RespondToReservation,
        data: { type: ReservationResponseType.Accepted, noteFromRestaurant: acceptedReason.current },
        reservationId: reservation.id,
      })
    )
    dispatch(getReservations())
  }

  const rejectTextFirstPart = `Ești sigur că dorești să refuzi cererea de rezervare pentru ${
    reservation.numberOfPeople
  } persoane pentru data de ${moment(reservation.requestedDateTime).format('D MMMM')} la ora ${moment(
    reservation.requestedDateTime
  ).format('HH:mm')}?`
  const rejectTextSecondPart = `${reservation.consumer.fullName} va fi notificat prin e-mail la adresa ${reservation.consumer.emailAddress} și prin SMS. `

  const confirmRejectReservation = async () => {
    await window.showModal({
      title: `Refuză cererea de rezervare`,
      textComponent: (
        <>
          {rejectTextFirstPart}
          <br />
          <br />
          {rejectTextSecondPart}
          <ReasonBox type="rejected" onUpdate={reasonText => (rejectionReason.current = reasonText)} />
        </>
      ),
      confirmButtonText: 'Da, refuză',
      cancelButtonText: 'Nu',
    })
    await dispatch(
      patchReservation({
        type: ConsumerReservationPatchType.RespondToReservation,
        data: { type: ReservationResponseType.Rejected, noteFromRestaurant: rejectionReason.current },
        reservationId: reservation.id,
      })
    )
    dispatch(getReservations())
  }

  return (
    <Wrapper>
      <div className={`reservation ${hasBody ? 'with-continuation' : ''}`}>
        <div className="left-section">
          <div className="center-row">
            <img src="/icons/user.png" alt="Person Icon" />
            <p className="action-title">{reservation.consumer.fullName}</p>
          </div>
          <div className="center-row" style={{ marginTop: -4, marginBottom: 8, fontSize: 14 }}>
            <p className="when">
              acum {moment(reservation.timestamp).fromNow(true)} (la {moment(reservation.timestamp).format('HH:mm')})
            </p>
          </div>
          <div className="center-row">
            <img src="/icons/group.png" alt="Group Icon" />
            <p className="when">
              rezervare pentru <b>{reservation.numberOfPeople}</b> persoane
            </p>
          </div>
          <div className="center-row">
            <img src="/icons/reservation-calendar.png" alt="Calendar Icon" />
            <p className="when">
              pe <b>{displayDate}</b>
            </p>
          </div>
          <div className="center-row">
            <img src="/icons/envelope.png" alt="Email Icon" />
            <p className="when">
              <a href={`emailto:${reservation.consumer.emailAddress}`}>{reservation.consumer.emailAddress}</a>
            </p>
          </div>
          <div className="center-row">
            <img src="/icons/phone.png" alt="Phone Icon" />
            <p className="when">
              <a href={`tel:${reservation.consumer.phoneNumber}`}>{reservation.consumer.phoneNumber}</a>{' '}
            </p>
          </div>
          {reservation.extraComments && (
            <div className="center-row">
              <img src="/icons/comment.png" alt="Comments Icon" />
              <p className="when">"{reservation.extraComments}"</p>
            </div>
          )}
        </div>

        <div className="reservation-number">{reservation.reservationNumber?.slice(0, 4)}</div>
      </div>

      {!isRepliedTo && !isExpired && (
        <div className="action-buttons">
          <Button
            style={{ background: `linear-gradient(313deg, rgb(140, 10, 40), rgb(167, 0, 38))` }}
            text="Confirmă Rezervarea"
            onClick={confirmAcceptReservation}
          />
          <Button variant="as-text" text="Refuză" onClick={confirmRejectReservation} />
        </div>
      )}

      {isRepliedTo && !reservation.finalized && (
        <div className="information-row">
          <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 8 }} />

          <span className="confirmed-text">
            {reservation.waiterResponse?.type === ReservationResponseType.Accepted ? 'Confirmată' : 'Refuzată'}
            {' acum '}
            {moment(reservation.waiterResponse?.timestamp).fromNow(true)}
          </span>
        </div>
      )}
      {reservation.finalized && (
        <div className="information-row">
          <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 8 }} />
          <span className="confirmed-text">
            Finalizată de
            <span className="bold"> {reservation.finalized?.byWaiterName} </span>
            acum {moment(reservation.finalized?.at).fromNow(true)}{' '}
          </span>
        </div>
      )}
      {isExpired && (
        <div className="information-row disabled">
          <FontAwesomeIcon icon={faWarning} style={{ marginRight: 8 }} />
          <span className="confirmed-text">Neconfirmată & Expirată</span>
        </div>
      )}
    </Wrapper>
  )
}
