import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent } from './styles'
import CloseButton from 'src/global/CloseButton'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser, setBusinessLink, setProductSuggestion } from 'src/state/users'
import Toggle from 'react-toggle'
import { Backdrop } from '../ProductModal/styles'
import { BusinessLinkType, isBusiness, LanguageCode, MenuProduct, UserPatchType } from '@meniudigital/shared'
import Button from 'src/global/Button'
import ProductPicker from './ProductPicker'
import Product from './ProductPicker/Product'
import { productsSelector } from 'src/state/selectors'
import { faCheckCircle, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Setting from './Setting'
import { IS_DESKTOP_OR_TABLET } from '..'
import LocationSetting from './LocationSetting'
import ScheduleSetting from './ScheduleSetting'
import OfflineSetting from './OfflineSetting'
import PartyModeSetting from './PartyModeSetting'
import CurrencySetting from './CurrencySetting'
import MaintenanceSetting from './MaintenanceSetting'

type SocialMediaSetting = {
  type: BusinessLinkType
  name: string
  placeholder: string
}

const allSocialMediaSettings: SocialMediaSetting[] = [
  {
    type: BusinessLinkType.Facebook,
    name: 'Pagina de Facebook',
    placeholder: `Linkul paginii (ex: https://facebook.com/mybistro)`,
  },
  {
    type: BusinessLinkType.Instagram,
    name: 'Pagina de Instagram',
    placeholder: `Linkul paginii (ex: https://instagram.com/mybistro)`,
  },
  {
    type: BusinessLinkType.PhoneNumber,
    name: 'Numărul de Telefon',
    placeholder: `Numărul tău de telefon`,
  },
  {
    type: BusinessLinkType.TripAdvisor,
    name: 'Pagina de TripAdvisor',
    placeholder: `Linkul paginii (ex: https://tripadvisor.com/demo_restaurant.html)`,
  },
  {
    type: BusinessLinkType.NutritionalValuesExternal,
    name: 'Link extern valori nutriționale',
    placeholder: `Linkul unde se pot găsi valorile nutriționale detaliate`,
  },
]

export default function SettingsModal() {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { user, isLoading: isLoadingUser } = useSelector((state: State) => state.users)
  const { list: products, isLoading: isLoadingProducts } = useSelector(productsSelector)
  const [invalidFields, setInvalidFields] = useState({})

  useEffect(() => {
    window.openSettingsModal = open
    window.hideSettingsModal = close
  }, [])

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const isBusinessInfoVisible = (mediumType: BusinessLinkType) => {
    return (user?.businessInfo || []).some(x => x.type === mediumType && x.isVisible === true)
  }

  const toggleBusinessMedium = (mediumType: BusinessLinkType) => {
    const currentSetting = (user?.businessInfo || []).find(x => x.type === mediumType)
    dispatch(setBusinessLink({ isVisible: !currentSetting?.isVisible, url: currentSetting?.url || '', type: mediumType }))
  }

  const toggleProductSuggestion = () => {
    const currentSuggestion = user?.activeProductSuggestion
    dispatch(setProductSuggestion({ isActive: !currentSuggestion?.isActive, productId: currentSuggestion?.productId || '' }))
  }

  const setSuggestedProductId = (newProductId: string | undefined) => {
    const currentSuggestion = user?.activeProductSuggestion
    if (!newProductId) {
      dispatch(setProductSuggestion({ isActive: false, productId: '' }))
    } else {
      dispatch(setProductSuggestion({ isActive: currentSuggestion?.isActive || false, productId: newProductId }))
    }
  }

  const testValidity = (newUrl: string, mediumType: BusinessLinkType) => {
    if (!newUrl) {
      setInvalidFields({ ...invalidFields, [mediumType]: true })
    }

    try {
      new URL(newUrl)
      setInvalidFields({ ...invalidFields, [mediumType]: false })
      return false
    } catch (e) {
      setInvalidFields({ ...invalidFields, [mediumType]: true })

      return true
    }
  }

  const updateBusinessMediumLinkUrl = (newUrl: string, mediumType: BusinessLinkType) => {
    const currentSetting = (user?.businessInfo || []).find(x => x.type === mediumType)
    dispatch(setBusinessLink({ isVisible: currentSetting?.isVisible || false, url: newUrl, type: mediumType }))
  }

  const isUrlInvalid = (type: BusinessLinkType) => {
    if (type === BusinessLinkType.PhoneNumber) {
      return false
    }

    const url = (user?.businessInfo || []).find(x => x.type === type)?.url

    if (!url) {
      return false
    }
    try {
      new URL(url)
      return false
    } catch (e) {
      return true
    }
  }

  if (!user) {
    return null
  }

  const isSuggestionActive = Boolean(user?.activeProductSuggestion?.isActive)
  const suggestedProduct = products.find(x => x.id === user?.activeProductSuggestion?.productId)

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Setări meniu
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-18px' }} />
        </Header>
        {isOpen && (
          <ModalContent>
            <p className="setting-explainer" style={{ marginTop: 0 }}>
              Ce limbi dorești să folosești pentru meniul tău?
              <Button
                variant="secondary"
                icon={faGlobe}
                text="Alege limbi..."
                onClick={() => {
                  close()
                  window.openLanguageModal()
                }}
              />
            </p>

            {(user.username === 'ambercaffe' || user.username === 'demo' || user.email.includes('nistoralex.ro')) && (
              <>
                <div className="setting-group danger">
                  <OfflineSetting />
                  <MaintenanceSetting />
                </div>
              </>
            )}

            <div className="setting-explainer">Dorești afișarea unui mesaj personalizat pe meniul tău?</div>
            <Setting
              isBusinessOnly
              isDisabledInCurrentPlan={!isBusiness(user)}
              notGrouped
              multiline
              title={`Mesaj ${IS_DESKTOP_OR_TABLET ? 'personalizat ' : ''}pe meniu`}
              iconPath="/icons/message.png"
              placeholder="e.g. Echipa Demo Bistro vă urează o primăvară frumoasă! 🎉🐰💐"
              isVisible={user?.customMenuMessage?.isVisible}
              value={user?.customMenuMessage?.message?.[LanguageCode.Romanian]}
              onVisibilityToggle={() =>
                dispatch(
                  patchCurrentUser({
                    type: UserPatchType.CustomMenuMessage,
                    data: { ...(user.customMenuMessage || {}), isVisible: !user.customMenuMessage?.isVisible },
                  })
                )
              }
              onValueUpdate={newValue =>
                dispatch(
                  patchCurrentUser({
                    type: UserPatchType.CustomMenuMessage,
                    data: { ...(user.customMenuMessage || {}), message: { [LanguageCode.Romanian]: newValue } },
                  })
                )
              }
            />
            <div className="setting-explainer" style={{ marginTop: 8, marginBottom: 32 }}>
              <i className="fa fa-info-circle"></i> Mesajul personalizat va apărea în partea de sus a meniului tău, sub poza de
              copertă. (max. 200 caractere)
            </div>

            <div className="setting-explainer">Ce informații despre businessul tău dorești să apară pe meniu?</div>
            <div className="social-media">
              {allSocialMediaSettings.map(({ type, name, placeholder }) => (
                <Setting
                  key={type}
                  title={name}
                  iconPath={`/logos/${type}.png`}
                  isVisible={isBusinessInfoVisible(type)}
                  value={(user?.businessInfo || []).find(x => x.type === type)?.url}
                  placeholder={placeholder}
                  onValueUpdate={newValue => updateBusinessMediumLinkUrl(newValue, type)}
                  onEveryKeyPress={newValue => testValidity(newValue, type)}
                  onVisibilityToggle={() => toggleBusinessMedium(type)}
                  hasError={(isUrlInvalid(type) || (invalidFields as any)[type]) && type !== BusinessLinkType.PhoneNumber}
                />
              ))}
              <Setting
                title="Rețeaua și Parola WiFi"
                iconPath="/icons/wifi.png"
                isVisible={user.wifiInfo?.isVisible}
                value={user.wifiInfo?.networkName}
                placeholder="Nume rețea WiFi"
                onValueUpdate={newValue =>
                  dispatch(
                    patchCurrentUser({
                      type: UserPatchType.WifiInfo,
                      data: { ...(user.wifiInfo || {}), networkName: newValue },
                    })
                  )
                }
                onVisibilityToggle={() =>
                  dispatch(
                    patchCurrentUser({
                      type: UserPatchType.WifiInfo,
                      data: { ...(user.wifiInfo || {}), isVisible: !user.wifiInfo?.isVisible },
                    })
                  )
                }
                withSecondaryValue
                secondaryValue={user.wifiInfo?.password}
                secondaryPlaceholder="Parolă WiFi"
                onSecondaryInputBlur={newValue =>
                  dispatch(
                    patchCurrentUser({
                      type: UserPatchType.WifiInfo,
                      data: { ...(user.wifiInfo || {}), password: newValue },
                    })
                  )
                }
              />

              <LocationSetting />
              <ScheduleSetting />
            </div>
            <div className="setting-explainer">Dorești împărțirea categoriilor tale pe secțiuni/meniuri diferite?</div>
            <Setting
              noInput
              notGrouped
              title={IS_DESKTOP_OR_TABLET ? 'Secțiuni (Meniuri) diferite (Bar, Restaurant etc.)' : 'Secțiuni Meniu'}
              iconPath="/icons/bar-restaurant-split.png"
              isVisible={user.isUsingMenuSections}
              onVisibilityToggle={() => {
                dispatch(patchCurrentUser({ type: UserPatchType.IsUsingMenuSections, data: !user.isUsingMenuSections }))
              }}
            />
            {user.isUsingMenuSections && (
              <div className="setting-explainer" style={{ marginTop: 8, marginBottom: 32 }}>
                <i className="fa fa-info-circle"></i> Pentru a gestiona meniurile tale, alege secțiunea din care face parte
                fiecare categorie în momentul editării sau creării acestora.
              </div>
            )}

            <div className="setting-explainer" style={{ marginTop: '40px' }}>
              Dorești să evidențiezi un produs la deschiderea meniului?
            </div>
            <div className="setting">
              <div className="left-side">
                <div className="setting-name">
                  <div className="setting-icon">
                    <img src="/icons/like.png" alt="Suggestion icon" />
                  </div>
                  <span>"Recomandarea Noastră de Astăzi"</span>
                  {isSuggestionActive && <div className="bullet" />}
                </div>
                {isSuggestionActive && (
                  <>
                    {suggestedProduct && (
                      <div style={{ marginTop: '16px', borderWidth: 10, borderColor: 'red' }}>
                        <Product
                          query={''}
                          product={suggestedProduct}
                          languageCode={LanguageCode.Romanian}
                          inPartyMode={Boolean(user?.inPartyMode)}
                          user={user!}
                          onRemoveClick={() => setSuggestedProductId(undefined)}
                        />
                      </div>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                      <Button
                        variant="as-text"
                        text={suggestedProduct ? 'Schimbă produsul...' : 'Alege produs...'}
                        onClick={() => window.openProductPicker()}
                        style={{ marginTop: '0', paddingLeft: 0 }}
                      />
                      {suggestedProduct && (
                        <div className="right-side" style={{ display: 'inline-flex' }}>
                          <span className="only-desktop">{isSuggestionActive ? 'Activ' : 'Inactiv'}</span>
                          <Toggle
                            className="toggle"
                            checked={isSuggestionActive}
                            icons={false}
                            onChange={() => toggleProductSuggestion()}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {(!isSuggestionActive || !suggestedProduct) && (
                <div className="right-side">
                  <span className="only-desktop">{isSuggestionActive ? 'Activ' : 'Inactiv'}</span>
                  <Toggle
                    className="toggle"
                    checked={isSuggestionActive}
                    icons={false}
                    onChange={() => toggleProductSuggestion()}
                  />
                </div>
              )}
            </div>

            <PartyModeSetting />

            <CurrencySetting />

            <div className="auto-save" style={{ opacity: isLoadingUser || isLoadingProducts ? 0 : 1 }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Toate modificările au fost salvate.
            </div>

            <ProductPicker onProductPick={(product: MenuProduct) => setSuggestedProductId(product.id)} />
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
