import React, { useEffect, useState } from 'react'
import { Modal, Header, ModalContent, ModalFooter } from './styles'
import CloseButton from 'src/global/CloseButton'
import { Backdrop } from '../../HomeScreen/ProductModal/styles'
import Button from 'src/global/Button'
import { PickedImageType } from '..'
import Cropper, { Area } from 'react-easy-crop'
import getImageBase64 from 'src/helpers/getImageBase64'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Slider from 'src/global/Slider'
import getCroppedImageFile from 'src/helpers/getCroppedImage'

type Props = {
  onCropCommit: (croppedImage: File, type: PickedImageType) => void
}

type Image = {
  file: File
  base64: string
  type: PickedImageType
}

const initialCrop = { x: 0, y: 0 }
const initialZoom = 100

export default function CropImageModal({ onCropCommit }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState<Image>()
  const [cropPoint, setCropPoint] = useState(initialCrop)
  const [cropArea, setCropArea] = useState<Area>()
  const [zoom, setZoom] = useState(initialZoom)

  useEffect(() => {
    window['openCropImageModal'] = open
  }, [])

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels)
  }

  const open = async (pickedImage: File, type: PickedImageType) => {
    setImage({ file: pickedImage, base64: await getImageBase64(pickedImage), type })
    setIsOpen(true)
    setCropPoint(initialCrop)
    setZoom(initialZoom)
  }

  const convertAndSetZoom = (zoomValue: number) => {
    setZoom(zoomValue * 100)
  }

  const commitCrop = async () => {
    const croppedImageFile = await getCroppedImageFile(image?.base64!, cropArea!)

    const renamedFile = new File([croppedImageFile], image?.file.name!, {
      type: croppedImageFile.type,
      lastModified: croppedImageFile.lastModified,
    })

    onCropCommit(renamedFile, image?.type!)
    close()
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Backdrop onClick={close} className={isOpen ? 'open' : 'closed'} style={{ zIndex: 299 }} />
      <Modal className={isOpen ? 'open' : 'closed'}>
        <Header>
          Redimensionează {image?.type === PickedImageType.Logo ? 'logo-ul' : 'poza de copertă'}
          <CloseButton onClick={close} title="Închide" style={{ marginTop: '-20px' }} />
        </Header>
        <ModalContent>
          <div className="crop-container">
            <Cropper
              image={image?.base64}
              crop={cropPoint}
              zoom={zoom / 100}
              aspect={image?.type === PickedImageType.Logo ? 1 : 1.5}
              cropShape={image?.type === PickedImageType.Logo ? 'round' : 'rect'}
              restrictPosition={false}
              onCropChange={setCropPoint}
              onCropComplete={onCropComplete}
              onZoomChange={convertAndSetZoom}
              cropSize={image?.type === PickedImageType.Logo ? { width: 270, height: 275 } : { width: 275, height: 206.25 }}
            />
          </div>
          <div className="controls">
            <i className="fa fa-search-plus-fas" />
            <div className="slider-wrapper">
              <Slider value={zoom} onChange={setZoom} min={1} max={400} />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button variant="as-text" onClick={close} text="Anulează" />
          <Button onClick={commitCrop} text="Salvează" icon={faCheck} />
        </ModalFooter>
      </Modal>
    </>
  )
}
