import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createAccountAndLogin, getCurrentUser } from 'src/state/users'
import { Wrapper } from './styles'
import Lottie from 'lottie-react'
import * as emailAnimation from 'src/assets/animations/email-tick.json'
import wait from 'src/helpers/wait'
import { Screen } from 'src/screens'
import useForm from 'src/helpers/useForm'
import Button from 'src/global/Button'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { registrationRequestsEndpoints, userEndpoints } from 'src/api'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function Register() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userEmail, setUserEmail] = useState('')
  const { formValues, isValid, register, setValue } = useForm()
  const [isConfirming, setIsConfirming] = useState(true)
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | undefined>(undefined)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [hasBlurredName, setHasBlurredName] = useState(false)
  const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    setTimeout(goToHomeIfLoggedIn, 500)
    populateUserEmail()
  }, [])

  const populateUserEmail = async () => {
    if (params.email) {
      setUserEmail(params.email)
      window.currentUserEmail = params.email
    } else if (params.registrationRequestId) {
      try {
        const registrationRequest = await registrationRequestsEndpoints.getById(params.registrationRequestId)
        if (!registrationRequest?.email) {
          onInvalidLink()
        } else {
          window.currentUserEmail = registrationRequest.email
          setUserEmail(registrationRequest.email)
        }
      } catch (e) {
        onInvalidLink()
      }
    } else {
      onInvalidLink()
    }
  }

  const onInvalidLink = async () => {
    setTimeout(async () => {
      await window.showModal({
        title: `Link invalid`,
        text: `Linkul accesat nu este valid`,
        confirmButtonText: 'Ok',
        isConfirm: true,
      })
      navigate(Screen.Home)
    }, 0)
  }

  const goToHomeIfLoggedIn = () => {
    if (localStorage.token) {
      navigate(Screen.Home)
    }
  }

  const finalizeAccount = async () => {
    const eventName = `clickOn FINALIZE_ACCOUNT`
    const data = { userEmail: window.currentUserEmail, formValues }

    window.logVisitorAction?.(eventName, data)

    const { password, passwordAgain, username, name, city } = formValues

    const isUsernameValid = await validateUsername()

    if (!isValid || !isUsernameValid) {
      return
    }

    if ((password.value || '').length < 6) {
      await window.showModal({
        title: `Parolă prea slabă`,
        text: `Parola trebuie să conțină minimum 6 caractere.`,
        confirmButtonText: 'Ok',
        isConfirm: true,
      })
      return
    }

    if (password.value !== passwordAgain.value) {
      await window.showModal({
        title: `Parole diferite`,
        text: `Parolele nu sunt identice. Încearcă din nou.`,
        confirmButtonText: 'Ok',
        isConfirm: true,
      })
      return
    }

    const captchaToken = await executeRecaptcha?.('finalizeAccount')

    if (!captchaToken) {
      return
    }

    setHasSubmitted(true)

    const { gtag, fbq } = window as any

    gtag?.('event', 'conversion', { send_to: 'AW-10816029167/uTgDCLD3zdUDEO-DvqUo' })
    fbq?.('track', 'CompleteRegistration', { content_category: 'email' })

    await dispatch(
      createAccountAndLogin({
        email: userEmail,
        password: password.value,
        username: username.value,
        name: name.value,
        city: city.value,
        captchaToken,
      })
    )
    await dispatch(getCurrentUser())
    await wait(1500)
    setIsConfirming(false)
    await wait(2000)
    navigate(Screen.Home)
  }

  const validateUsername = async () => {
    const { username } = formValues

    setIsValidating(true)

    const response = await userEndpoints.validateUsername(username.value)

    setIsValidating(false)
    setIsUsernameAvailable(response.isAvailable)
    return response.isAvailable
  }

  const normalizeUsername = (input: string) =>
    input
      .normalize('NFKD')
      .replace(/[^\w]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')

  const setUsernameAutomatically = () => {
    if (formValues.name?.value) {
      setValue('username', normalizeUsername(formValues.name?.value || ''))
      validateUsername()
      setHasBlurredName(true)
    }
  }

  return (
    <Wrapper onKeyDown={e => e.keyCode === 13 && finalizeAccount()}>
      <div className="page">
        {!hasSubmitted && (
          <>
            <h3>Finalizează contul</h3>
            <form>
              <div className="email-header">
                <i className={userEmail.includes('gmail') ? 'fa fa-gmail' : 'fa fa-mail-entypo'}></i>
                <span style={{ fontSize: userEmail.length > 30 ? 15 : 16 }}>{userEmail}</span>
              </div>

              <span className="question" style={{ marginTop: '8px' }}>
                Cum se numește localul tău?
              </span>

              <input
                type="text"
                onBlur={setUsernameAutomatically}
                maxLength={30}
                placeholder="e.g. Pizzeria Maria, Domino Bistro, Caffe Costa"
                className="data-hj-allow"
                {...register('name')}
              />

              {(formValues.name?.value || '').toLowerCase().includes('test') && (
                <div className="error">
                  Numele localului tău nu poate conține cuvântul "test". Introdu datele reale a localului tău.
                </div>
              )}

              <span className="question">Unde se află localul tău?</span>
              <input
                autoComplete="city"
                type="text"
                maxLength={50}
                placeholder="e.g. Oradea, jud. Bihor (Oraș și Județ)"
                className="data-hj-allow"
                {...register('city')}
              />

              <div
                className="expanded-fields"
                style={{ display: hasBlurredName || (formValues.city?.value || '').length > 0 ? 'flex' : 'none' }}
              >
                <span className="question">ID restaurant / URL</span>
                <input
                  autoComplete="username"
                  type="text"
                  id="username"
                  placeholder="e.g. bistroitalia"
                  maxLength={30}
                  onBlur={validateUsername}
                  className="data-hj-allow"
                  {...register('username', false, () => setIsUsernameAvailable(true), normalizeUsername)}
                />
                <div className="url-explainer">
                  <i className="fa fa-info-circle"></i> ID-ul localului definește URL-ul (<strong>codul QR</strong>) la care va fi
                  disponibil meniul tău. Acesta nu poate conține spații sau caractere speciale, și{' '}
                  <strong>nu va putea fi schimbat pe viitor.</strong>.
                  <br />
                </div>

                {(formValues.username?.value || '').length > 0 && isUsernameAvailable !== false && (
                  <div className="url-preview">
                    URL-ul meniului tău va fi:
                    <div className="url">
                      https://meniudigital.ro/
                      <span className="slug">{formValues.username.value}</span>
                    </div>
                    <div className="url-explainer">
                      <i className="fa fa-info-circle"></i> Acest link nu poate fi schimbat pe viitor. Alege cu grijă.
                      <br />
                    </div>
                  </div>
                )}

                {(formValues.username?.value || '').length > 0 && !isValidating && isUsernameAvailable === false && (
                  <div className="error">Acest ID de restaurant nu e disponibil. Încearcă altul.</div>
                )}

                <span className="question">Alege o parolă</span>
                <input type="password" maxLength={50} placeholder="Parolă" {...register('password')} />

                <span className="question">Confirmă parola</span>
                <input type="password" maxLength={50} placeholder="Confirmă parola" {...register('passwordAgain')} />

                <Button
                  text="Continuă"
                  disabled={!isValid || !isUsernameAvailable || isValidating}
                  icon={faArrowRight}
                  onClick={finalizeAccount}
                />
              </div>
            </form>
          </>
        )}

        {hasSubmitted && (
          <div className="panel">
            <div className="icon">
              <Lottie
                {...{
                  loop: true,
                  autoplay: true,
                  animationData: emailAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={100}
                width={100}
              />
            </div>
            <span>{isConfirming ? 'Se creează contul...' : 'Cont creat!'}</span>
          </div>
        )}
      </div>
    </Wrapper>
  )
}
